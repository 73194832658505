/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --header-background: #fff;
    --header-color: #000;
    --header-contacts-color: #414141;
    --header-icon-stroke-width: 2px;
    --header-search-clear-background: #838383;
    --header-desktop-button-color: var(--primary-base-color);
    --header-height: 70px;
    --header-nav-height: 70px;
    --header-total-height:
        calc(
            var(--header-height)
            + var(--offline-notice-height)
            + var(--demo-notice-height)
            + env(safe-area-inset-top)
        );

    @include desktop {
        --header-dots-color: #000;
    }

    @include after-mobile {
        --header-nav-height: 75px;
        --header-menu-height: 50px;
        --header-height: calc(var(--header-nav-height) + var(--header-menu-height));
    }
}

@supports not (height: env(safe-area-inset-top)) {
    :root {
        --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height));
    }
}

@mixin button-invisible {
    opacity: 0;
    padding: 0;
    width: 0;
    pointer-events: none;
    overflow: hidden;
}

@mixin button-visible {
    opacity: 1;
    padding: 7.5px;
    width: 35px;
    pointer-events: all;
    overflow: visible;
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

.Header {
    --header-logo-width: 0;
    --header-logo-height: 0;

    position: fixed;
    top: var(--demo-notice-height);
    left: 0;
    width: 100%;
    z-index: 99999;
    background-color: var(--header-background);
    padding-top: env(safe-area-inset-top, 0);
    min-height: 75px;

    &-Button_type_minicart {
        margin-top: 3px!important;
    }

    @include before-desktop {
       min-height: 70px;
    }

    // @include desktop {
    //     border-bottom: 1px solid var(--primary-divider-color);
    // }

    @at-root .hiddenHeader {
        @include before-desktop {
            /* stylelint-disable-next-line length-zero-no-unit */
            --header-height: 0px;
        }
    }

    .SearchField {
        border-left: 1px solid var(--color-black);
        margin-left: 35px;

        @include before-desktop {
            margin-left: 10px;
        }
    }

    .icon-favoris {
        margin-top: 2px;
    }

    .icon-favoris-empty,
    .icon-favoris-fill,
    .icon-favoris, .icon-account {
        font-size: 22px;

        @include before-desktop {
           font-size: 18px;
        }
    }

    &-MyAccount {
        display: flex;
        align-items: center;
    }

    &-MyAccountWrapper {
        color: inherit;
        text-decoration: none;
        position: relative;
    }

    &-MyAccountWrapperSubmenu {
        position: absolute;
        top: calc(100% + 1.2rem);
        right: -1rem;
        width: 17rem;
        background: $white;
        border: 1px solid $greyE7;
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem;
        z-index: 2;
        visibility: hidden;

        &_active {
          visibility: visible;
        }
    }

    &-MyAccountWrapperSubmenuIcon {
        width: 17px;
        position: absolute;
        bottom: 100%;
        right: 17px;
    }

    &-MyAccountWrapperSubmenuItem {
        color: $black;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-family: $futuraDemi;
        padding: 0.5rem 0;
        text-decoration: none;
        cursor: pointer;
        text-align: left;

        &:hover {
          text-decoration: none;
        }
    }

    .icon-favoris-empty,
    .icon-favoris-fill,
    .icon-favoris {
        margin-left: 14px;
        cursor: pointer;

        @include before-desktop {
            font-size: 16px;
        }
    }

    &-ContactLink {
        margin-top: 3px;
    }

    &-ContactLink:hover,
    &-ContactLink:visited,
    &-ContactLink:link,
    &-ContactLink:active {
        text-decoration: none;
    }

    &-helpBtn {
        color: $black;
        font-size: 2.3rem;
        margin-right: 10px;
        cursor: pointer;
    }

    &-Wrapper {
        margin-bottom: var(--header-total-height);

        @include desktop {
            &_noMenu {
                margin-bottom: calc(var(--header-total-height) - 50px);    
            }
        }
    }

    &_isHiddenOnMobile {
        @include before-desktop {
            pointer-events: none;
            opacity: 0;
        }
    }

    &_name {
        &_cart {
            .Header-Button_type_minicart {
                @include desktop {
                    --header-color: var(--primary-base-color);
                }
            }
        }

        &_cart_overlay {
            .Header-Button_type_minicart,
            .Header-MinicartTitle {
                @include after-mobile {
                    --header-color: var(--primary-base-color);
                }
            }
        }

        &_menu,
        &_menu_subcategory {
            .Header-Button_type {
                &_close {
                    @include desktop {
                        @include button-visible;
                    }
                }

                &_menu {
                    @include desktop {
                        @include button-invisible;
                    }
                }
            }
        }

        &_customer_account,
        &_customer_sub_account {
            .Header-Button_type_account,
            .Header-MyAccountTitle {
                @include desktop {
                    --header-color: var(--header-desktop-button-color);
                }
                margin: auto;
            }
        }

        &_checkout {
            .Header-Button,
            .Header-SearchWrapper,
            .SearchField {
                @include desktop {
                    display: none;
                }
            }
        }
    }

    &_isCheckout {
        .Header {
            &-Button_type_account {
                display: block;
            }

            &-Nav {
                @include desktop {
                    justify-content: flex-end;
                }
            }

            &-MyAccount {
                @include before-desktop {
                    position: absolute;
                    right: 10px;
                    width: auto;
                }
            }
        }

        .MyAccountOverlay {
            @include before-desktop {
                top: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Banner {
        background: var(--primary-base-color);
        color: $white;
        height: 50px;
        line-height: 50px;
        text-align: center;
        display: block;
        z-index: 100;

        strong {
            margin-left: 3px;
        }
    }

    &-TopMenu,
    &-Nav {
        display: flex;
        margin: auto;
        max-width: var(--content-wrapper-width);
    }

    &-TopMenu {
        height: var(--header-top-menu-height);
        justify-content: space-between;
        z-index: 21;
        padding: {
            left: 1.2rem;
            right: 2.4rem;
            top: 1.2rem;
        };

        @include mobile {
            padding: {
                left: 1.4rem;
                right: 2.8rem;
                top: 1.4rem;
            }
        }
    }

    &-Contacts {
        font-size: 1.2rem;
        color: var(--header-contacts-color);

        span:first-child {
            margin-right: 20px;
        }
    }

    &-Nav {
        justify-content: space-between !important;
        width: 100%;
        align-items: center;
        height: var(--header-nav-height);
        padding: 0 1.2rem;
        z-index: 101;
        border-bottom: 1px solid $greyE7;

        @include before-desktop {
           height: 70px;
        }

        @include mobile {
            padding: 0 1.4rem;
        }

        @include desktop {
            justify-content: flex-end;
        }

        > div {
            display: flex;
            align-items: center;

            &.leftBloc {
                padding-left: 3rem;

                @include before-desktop {
                    padding-left: 0;
                }
            }

            &.rightBloc {
                justify-content: flex-end;
                padding-right: 2.16rem;

                @include before-desktop {
                    paddin-right: 0;

                    .Header-Button.Header-Button_type_minicart:not(:first-child) {
                        opacity: 1;
                        pointer-events: auto;

                        &:before, &:after {
                            content: none;
                        }
                    }
                }
            }
        }
    }

    &-Button {
        @include button-invisible;

        cursor: pointer;
        margin: 0;
        height: 35px;

        &_isVisible {
            @include before-desktop {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: .1;
        }

        &_type {
            &_menu,
            &_account,
            &_minicart {
                @include desktop {
                    @include button-visible;
                }
            }

            &_close {
                @include close-button;

                @include mobile {
                    position: absolute;
                    top: 72px;
                    right: 0px;
                }

                display: none;
                visibility: hidden;
            }

            &_back {
                @include back-button;
            }

            &_menu {
                @include menu-button;

                @include desktop {
                    width: 100px;
                }
            }

            &_account {
                @include account-button;

                &.Header-Button_isVisible {
                    overflow: hidden;
                }
            }

            &_minicart {
                @include before-desktop {
                    @include minicart-button;
                }

                @include desktop {
                    cursor: default;
                    height: auto;
                    width: auto;
                }

                cursor: default;
                height: auto;
                width: auto;
            }

            &_searchClear {
                @include clear-search-button;
            }

            &_clear {
                @include clear-button;
            }

            &_edit {
                text-transform: uppercase;

                &.Header-Button_isVisible {
                    width: 70px;
                    opacity: .5;
                }
            }

            &_share {
                @include share-button;

                &.Header-Button_isVisible {
                    height: 0;
                    width: 0;
                    margin-left: 1rem;
                    opacity: .5;
                }
            }

            &_home {
                @include home-icon;
            }

            &_ok,
            &_cancel {
                font-size: 1.4rem;
                opacity: .5;

                &.Header-Button_isVisible {
                    width: 70px;
                }
            }

            &_compare {
                @include button-visible;

                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-CompareButtonWrapper {
        border-right: 2px dotted var(--header-dots-color);
        margin: 0 20px 0 7px;
        padding-right: 20px;
    }

    &-CompareIcon {
        @include compare-icon(var(--header-color));
    }

    &-MyAccount {
        &Wrapper {
            align-items: center;
            display: flex;
        }

        &Title {
            cursor: pointer;
            padding-right: .48rem;
            text-transform: uppercase;
            color: var(--header-color);
            margin: auto;

            @include mobile {
                padding-right: .56rem;
                display: none;
            }
        }
    }

    &-Minicart {
        &ButtonWrapper {
            align-items: center;
            cursor: pointer;
            display: flex;
            height: 26px;
            // margin-left: 2.4rem;
            // padding-right: 2.16rem;

            // @include mobile {
            //     // margin-left: 2.8rem;
            //     // padding-right: 2.52rem;
            // }

            @include tablet-portrait {
                margin-left: 0;
            }
        }

        &Title {
            color: var(--header-color);
            margin-right: .84rem;
            text-transform: uppercase;

            @include mobile {
                margin-right: .98rem;
            }
        }

        &Icon {
            @include minicart-button;

            @include desktop {
                height: 20px;
                width: 20px;
                top: 1px;
            }
        }

        &ItemCount {
            line-height: 13px;
            min-width: 14px;
            padding-left: 5px;
            background: black;
            border-radius: 10px;
            color: var(--header-background);
            display: inline-block;
            font-size: 1.2rem;
            padding: 3px 5px 1px;
            position: absolute;
            transform: translateX(3px);
            z-index: 2;

            @include after-mobile {
                left: 100%;
                transform: translateX(-30px);
                top: 12px;
            }

            @include mobile {
                left: 50%;
                top: 18px;
            }
        }
    }

    &-LogoWrapper {
        position: absolute;
        // height: var(--header-logo-height);
        // width: var(--header-logo-width);
        width: 159px;
        heigtht: 100%;
        max-width: 0;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        opacity: 0;
        overflow: hidden;

        transition-property: opacity;
        transition-duration: 200ms;

        @include desktop {
            @include logo-visible;
        }

        @include before-desktop {
            @include logo-visible;
            max-width: initial;
            width: 25%;
        }

        &_isVisible {
            @include logo-visible;
        }

        .Image-Image {
            // object-fit: fill;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: auto;
            display: block;

        }
    }

    &-Burger {
        order: 3;
        margin-left: 10px;

        @include mobile {
            align-self: center;
        }

        > div {
            width: 18px;
            height: 2px;
            background: $black;

            &:not(:first-child){
                margin-top: 4px;
            }
        }
    }

    .FieldSelect > select {
        font-size: 2rem !important;
        font-family: $futuraDemi !important;
        text-transform: uppercase !important;
    }

    &-Title {
        opacity: 0;
        max-width: 0;
        transition-property: opacity;
        transition-duration: 200ms;

        text-align: center;
        font-size: 1.56rem;
        color: var(--header-color);

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        pointer-events: none;

        position: absolute;
        margin: auto;
        width: 100%;
        height: 1.8rem;
        line-height: 1.8rem;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include mobile {
            text-transform: uppercase;
            overflow: unset;
            white-space: unset;
            font-size: 1.82rem;
            height: 2.1rem;
            line-height: 2.1rem;
        }

        // &_isVisible {
        //     @include before-desktop {
        //         opacity: 1;
        //         max-width: calc(100% - 180px);
        //     }
        // }
    }

    a {
        &:hover,
        &:focus {
            @include before-desktop {
                text-decoration: none;
            }
        }
    }

    &-MenuContainer {
      height: var(--header-menu-height);
    }

    &-Wishlist {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 40vw;
        max-width: 390px;
        height: calc(100% - 7rem - env(safe-area-inset-bottom) - 55px);
        background: $white;
        z-index: 101;
        transform: translateX(100%);
        border-top: 1px solid $greyE7;
        padding: 2% 3%;
        box-sizing: border-box;
        padding-bottom: 0 !important;

        .MyAccountMyWishlist-ActionBar {
            display: none;
        }

        @include before-desktop {
            width: 100vw;
            padding: 20px;
            top: 7rem;
        }
    }

    &-WishlistWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &-WishlistOverlay {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: calc(100vh - 125px);
        background: rgba($black, 0.7);
        z-index: 100;
        opacity: 0;
        visibility: hidden;

        @include before-desktop {
            top: 7rem;
        }
    }
}
