/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --cart-overlay-totals-background: var(--secondary-base-color);
  --cart-overlay-divider-background: #d8d8d8;
  --cart-overlay-promo-background: #fff;
  --cart-overlay-width: 400px;
}

.CartOverlay {
  @include after-mobile {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 40vw;
    max-width: 390px;
    height: calc(100% - 7rem - env(safe-area-inset-bottom) - 55px);
    background: #fff;
    z-index: 101;
    border-top: 1px solid #e7e7e7;
    padding: 1em;
    box-sizing: border-box;
    padding-bottom: 0 !important;
  }

  &_isVisible {
    @include after-mobile {
      //border: 1px solid var(--overlay-desktop-border-color);
    }
  }

  &-Empty {
    padding: 1.2rem;

    @include mobile {
      padding: 1.4rem;
    }
  }

  &-Actions {
    display: flex;
    padding: 1.2rem;

    @include mobile {
      padding: 1.4rem;
    }
  }

  &-OutOfStockProductsWarning {
    padding: 1rem;
    display: flex;
    justify-content: center;
    background-color: var(--primary-error-color, red);
    font-size: 14px;
    font-weight: bold;
  }

  &-CheckoutButton {
    margin-left: 1.2rem;

    span {
      @include lock-button;
    }

    @include mobile {
      margin-left: 1.4rem;
      width: 100%;
      display: block;
      text-align: center;
    }

    @include after-mobile {
      flex-grow: 1;
      text-align: center;
    }
  }

  &-CartButton {
    @include mobile {
      display: none;
    }
  }

  &-Promo,
  &-Total {
    min-height: 4.8rem;

    @include mobile {
      min-height: 5.6rem;
    }

    @include after-mobile {
      min-height: 3.6rem;
    }
  }

  &-Promo {
    padding: 1.2rem;
    text-align: center;
    background: var(--cart-overlay-promo-background);
    margin-bottom: 0;

    @include mobile {
      padding: 1.4rem;
      border-bottom: 1px solid var(--cart-overlay-divider-background);
      border-top: 1px solid var(--expandable-content-color);
    }

    @include after-mobile {
      padding: .84rem;
      background: var(--primary-light-color);
    }

    strong {
      margin: 0 5px;
    }
  }

  &-Items {
    max-height: 40vh;
    overflow-y: scroll;
  }

  &-Total,
  &-Discount,
  &-Tax,
  &-Shipping {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 1.2rem .6rem;
    font-size: 1.8rem;
    background: var(--cart-overlay-totals-background);

    @include mobile {
      padding: 1.4rem 1.4rem .7rem;
    }

    @include after-mobile {
      font-size: 1.6rem;
    }

    dd {
      text-align: right;

      span {
        display: block;
        font-size: .6em;
        font-weight: 300;
      }
    }
  }

  &-Total {
    font-weight: bold;

    @include after-mobile {
      border-bottom: 1px solid var(--cart-item-divider-color);
    }
  }

  &-DiscountCoupon {
    &::after {
      content: ':';
      font-weight: normal;
    }
  }

  .closeContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1em;
    span {
      font-size: 20px;
      padding-top: 2px;
    }
    button {
      font-size: 15px;
    }
    &:hover {
      span {
        color: #f26323;
      }
      button {
        color: #f26323;
      }
    }
  }
  .error-border {
    border: 2px solid orangered!important;
    &::placeholder {
      color: orangered;
      opacity: 1;
    }
  }
  .CartOverlay-Comment {
    width: 100%;
    height: 150px;
    border: 2px solid var(--input-border-color);
  }
}
